export const getAzureConfig = () => {
  return {
    appId: "2940b826-09d5-43c8-bc1e-4c6295255a0c",
    authority: "https://login.microsoftonline.com/common",
    ssoRedirectPage: `${getSSOHost()}/accounts/login/sso/azure`,
    redirectUri: `${getSSOHost()}/accounts/login/sso/azure/success`,
    scopes: ["user.read"],
    mandatorySSOMethod,
  };
};

const thisHost = window.location.host;

const getSSOHost = () => {
  let host = thisHost;
  if (!host.includes("localhost")) {
    host = thisHost.includes("test.sustainabilitytool.com")
      ? "uk.test.sustainabilitytool.com"
      : "uk.sustainabilitytool.com";
    host = `https://${host}`;
  } else {
    host = `http://${host}`;
  }
  return host;
};

const mandatorySAMLDomains = {
  procedural: new Set(["procedural.build"]),
  sizewellc: new Set(["sizewellc.com"]),
  siemens: new Set(["siemens.com"]),
};

// if (thisHost.includes(".test.")) {
//   mandatorySAMLDomains.siemens = new Set(["siemens.com"]);
// }

const mandatoryOIDCDomains = {};

const isInDomainsSet = (domain, domains) => {
  return !!domains.intersection(new Set([domain])).size;
};

const mandatorySSOMethod = (email) => {
  try {
    let domain = email.toLowerCase().split("@")[1];
    console.log("checking mandatorySSOMethod for", domain);

    // Check through mandatory SAML domains
    for (let [directory, domains] of Object.entries(mandatorySAMLDomains)) {
      if (isInDomainsSet(domain, domains)) {
        console.log("mandatorySSOMethod is saml", domain, ", directory = ", directory);
        return { method: "saml", directory };
      }
    }

    // Check through mandatory OIDC domains
    for (let [key, domains] of Object.entries(mandatoryOIDCDomains)) {
      if (isInDomainsSet(domain, domains)) {
        console.log("mandatorySSOMethod is oidc", domain, ", directory = ", directory);
        return { method: "oidc", directory };
      }
    }
  } catch {
    console.log("error determining mandatorySSOMethod");
    return false;
  }
  return false;
};
